import { Game, Vector2 } from "./game";

export interface Config {
	path: string;
	dartAnimPath: string;
	dartAnimFrames: number;
	debug: boolean;

	dartMiss?: string;
	dartHit?: string;

	target?: string;
	targetCrack?: string;
	targetHit?: string;
	bullsEyeRadius: number;

	levels: LevelConfig[];

	missPenalty: number;

	scoreFont: string;
	scoreColor: string;
	scoreSize: number;
	scoreOffsetY: number;
	speedUp: number;

	hitMessage?: string;
	hitMessageSize: number;
	bullsEyeMessage?: string;
	bullsEyeMessageSize: number;

	messageFont: string;
	hitMessageColor: string;
	bullsEyeMessageColor: string;

	timeBarColor: string;
	timeBarBackgroundColor: string;
	timeBarBorderRadius: number;
	timeBarBorderColor: string;
	timeBarBorderWidth: number;
	timeBarOffsetY: number;

	hitPoints: number;
	bullsEyePoints: number;
	bonusPerMs: number;

	throwSound?: string;
	missSound?: string;
	hitSound?: string;
	timeBonusSound?: string;

	loopLevels?: boolean;
	minLevelTime: number;
}

export interface MotionConfig {
	startRotation: number;
	radius: number;
	circleFrames: number;
	counterClockWise?: boolean;
	wobbleFrames?: number;
	maxWobble?: number;
}

export interface LevelConfig {
	dartScale: number;
	timeLimit: number;
	targets: TargetConfig[];
}

export interface TargetConfig {
	position: Vector2;
	motion?: MotionConfig;
	points?: number;
	scale?: number;
	bullsEyePoints?: number;
	sprite: number;
	bullsEyeRadius?: number;
	hitRadius?: number;
}

export function printConfig(config: any) {
	let string = "";
	for (const [key, value] of Object.entries(config)) {
		string += key + "=" + value + "\n";
	}
	console.log(string);
}

export const _defaultConfig: Config = {
	path: "https://mediadev.playsome.nl/games/dart/darten3/",
	dartAnimFrames: 12,
	dartAnimPath: "dart_animation",

	scoreFont: "Acme",
	scoreColor: "#000000",
	scoreSize: 30,
	scoreOffsetY: 0,

	bullsEyeMessageSize: 40,
	hitMessageSize: 30,

	missPenalty: 25,
	loopLevels: true,
	minLevelTime: 0,
	debug: false,

	speedUp: 0.1,

	bullsEyeRadius: 30,

	bullsEyePoints: 2,
	hitPoints: 1,
	bonusPerMs: 1,

	messageFont: "Barrio",
	bullsEyeMessageColor: "#E4E701",

	hitMessageColor: "#000000",
	bullsEyeMessage: "",
	timeBarColor: "#06BB00",
	timeBarBackgroundColor: "grey",
	timeBarBorderColor: "black",
	timeBarBorderRadius: 0,
	timeBarBorderWidth: 3,
	timeBarOffsetY: 0,

	levels: [],
};
