import { MotionConfig, TargetConfig } from "./config";
import { Game, Vector2 } from "./game";

interface CircleMotion {
	rotation: number;
	circleCenter: Vector2;
	frames: number;
	radius: number;
	counterClockWise: boolean;
	dRotation: number;
	wobble?: Wobble;
}

interface Wobble {
	max: number;
	d: number;
}

export class Target {
	position: Vector2;

	motion: CircleMotion | null = null;

	sprite: number;
	spriteFront: HTMLImageElement;
	spriteBack: HTMLImageElement;

	radius: number;
	bullsEyesRadius: number;

	points: number;
	bullsEyePoints: number;

	isHit: boolean = false;
	bullsEye: boolean = false;

	textY = 5;
	textOpacity = 1;

	wobbleProgress = 0;

	scale: number;

	animated: boolean;

	constructor(
		game: Game,
		x: number,
		y: number,
		sprite: number,
		points: number,
		bullsEyePoints: number,
		hitRadius: number,
		bullsEyeRadius: number,
		scale: number,
		motion: MotionConfig
	) {
		this.position = { x: x, y: y };
		this.scale = scale;

		this.sprite = sprite;

		let spriteFront = game.sprites["target_front_" + sprite];
		let spriteBack = game.sprites["target_back_" + sprite];

		if (!spriteFront) {
			spriteFront = game.sprites.targetFront;
		}
		if (!spriteFront) {
			spriteFront = game.sprites.targetBack;
		}

		this.spriteFront = spriteFront;
		this.spriteBack = spriteBack;

		this.radius = (spriteFront.height / 2) * (hitRadius / 100);
		this.points = points;
		this.bullsEyePoints = bullsEyePoints;
		this.bullsEyesRadius = (bullsEyeRadius / 100) * this.radius;

		if (motion) {
			this.motion = {
				rotation: motion.startRotation * (Math.PI / 180),
				circleCenter: { x: x, y: y },
				frames: motion.circleFrames,
				counterClockWise: motion.counterClockWise ?? false,
				radius: motion.radius,
				dRotation:
					motion.circleFrames === 0
						? 0
						: ((360 * Math.PI) / 180 / motion.circleFrames) *
						  (motion.counterClockWise ? -1 : 1),
			};
			if (motion.wobbleFrames && motion.maxWobble) {
				this.motion.wobble = {
					max: motion.maxWobble,
					d: motion.maxWobble / (motion.wobbleFrames / 2),
				};
			}
		}
	}

	playBreakAnim() {}

	update() {
		if (this.motion === null) {
			return;
		}
		this.motion.rotation += this.motion.dRotation;
		let radius = this.motion.radius;

		if (this.motion.wobble) {
			this.wobbleProgress += this.motion.wobble.d;
			if (this.wobbleProgress >= this.motion.wobble.max * 2) {
				this.wobbleProgress =
					this.wobbleProgress - this.motion.wobble.max * 2;
			}
			radius +=
				(this.motion.wobble.max / 2) *
					Math.cos(
						(Math.PI / this.motion.wobble.max) * this.wobbleProgress
					) +
				this.motion.wobble.max / 2;
		}

		if (this.motion.frames === 0) {
			this.position.x =
				this.motion.circleCenter.x +
				Math.cos(this.motion.rotation) * radius;
			this.position.y =
				this.motion.circleCenter.y +
				Math.sin(this.motion.rotation) * radius;
		} else {
			radius *= Game.scale;
			this.position.x =
				this.motion.circleCenter.x +
				(Math.cos(this.motion.rotation) * radius) /
					Game.aspectRatioWidth;
			this.position.y =
				this.motion.circleCenter.y +
				(Math.sin(this.motion.rotation) * radius) /
					Game.aspectRatioHeight;
		}
	}

	render() {
		if (Game.config.debug) {
			let screenX = this.position.x * Game.aspectRatioWidth;
			let screenY = this.position.y * Game.aspectRatioHeight;
			let size = this.radius * this.scale * Game.scale;
			Game.drawShape("red", screenX, screenY, size, size, false);
		}

		if (this.isHit === false) {
			if (!this.spriteFront) {
				return;
			}
			let screenX =
				this.position.x * Game.aspectRatioWidth -
				(this.spriteFront.width * Game.scale * this.scale) / 2;
			let screenY =
				this.position.y * Game.aspectRatioHeight -
				(this.spriteFront.height * Game.scale * this.scale) / 2;
			Game.drawImage(
				this.spriteFront,
				screenX,
				screenY,
				this.spriteFront.width * this.scale * Game.scale,
				this.spriteFront.height * this.scale * Game.scale
			);
		} else {
			if (!this.spriteBack) {
				return;
			}
			let screenX =
				this.position.x * Game.aspectRatioWidth -
				(this.spriteBack.width * Game.scale * this.scale) / 2;
			let screenY =
				this.position.y * Game.aspectRatioHeight -
				(this.spriteBack.height * Game.scale * this.scale) / 2;
			Game.drawImage(
				this.spriteBack,
				screenX,
				screenY,
				this.spriteBack.width * this.scale * Game.scale,
				this.spriteBack.height * this.scale * Game.scale
			);
		}

		if (Game.config.debug) {
			let screenX = this.position.x * Game.aspectRatioWidth;
			let screenY = this.position.y * Game.aspectRatioHeight;
			let size = this.bullsEyesRadius * this.scale * Game.scale;
			Game.drawShape("blue", screenX, screenY, size, size, false);
		}
	}

	hit(bullsEye: boolean) {
		this.bullsEye = bullsEye;
		this.isHit = true;
		if (bullsEye) {
			Game.points += this.bullsEyePoints;
		} else {
			Game.points += this.points;
		}
	}

	tryToHit(dartPos: Vector2) {
		if (this.isHit === true) {
			return;
		}

		const x = this.position.x * Game.aspectRatioWidth;
		const y = this.position.y * Game.aspectRatioHeight;
		const bullsEyesRadius = this.bullsEyesRadius * Game.scale * this.scale;
		const radius = this.radius * Game.scale * this.scale;

		var distance = this.distanceBetween(dartPos, { x: x, y: y });

		if (distance < bullsEyesRadius) {
			this.hit(true);
			return true;
		} else if (distance < radius) {
			this.hit(false);
			return true;
		}
		return false;
	}

	distanceBetween(from: Vector2, to: Vector2) {
		return Math.sqrt(
			(to.x - from.x) * (to.x - from.x) +
				(to.y - from.y) * (to.y - from.y)
		);
	}
}
