import { Game, Vector2 } from "./game";
import { Target } from "./target";

export class Cracked {
  position: Vector2;
  img?: HTMLImageElement;
  scale: number;

  constructor(game: Game, pos: Vector2, scale: number, sprite: number) {
    this.position = pos;
    this.scale = scale;

    console.log(sprite);

    let img = game.sprites["target_crack_" + sprite];
    console.log("CRACK", img);
    if (!img) {
      img = game.sprites.targetCrack;
    }

    this.img = img;
  }

  forceY: number = 0;
  forceX: number = 0;

  render() {
    if (!this.img) {
      return;
    }
    const size = 50 * Game.scale * this.scale;
    this.forceX += 4.0;
    this.forceY += 0.8;

    let screenX = this.position.x * Game.aspectRatioWidth - this.forceX;
    let screenY =
      this.position.y * Game.aspectRatioHeight + Math.pow(this.forceY, 2);
    Game.drawImage(
      this.img,
      screenX - size / 2,
      screenY - size / 2,
      size / 2,
      size / 2,
      {
        width: size,
        height: size,
        x: 0,
        y: 0,
      }
    );

    screenX = this.position.x * Game.aspectRatioWidth - this.forceX * 1.5;
    screenY =
      this.position.y * Game.aspectRatioHeight + Math.pow(this.forceY * 1.5, 2);
    Game.drawImage(this.img, screenX - size / 2, screenY, size / 2, size / 2, {
      width: size,
      height: size,
      x: 0,
      y: size,
    });

    screenX = this.position.x * Game.aspectRatioWidth + this.forceX;
    screenY =
      this.position.y * Game.aspectRatioHeight + Math.pow(this.forceY, 2);
    Game.drawImage(this.img, screenX, screenY - size / 2, size / 2, size / 2, {
      width: size,
      height: size,
      x: size,
      y: 0,
    });

    screenX = this.position.x * Game.aspectRatioWidth + this.forceX * 1.5;
    screenY =
      this.position.y * Game.aspectRatioHeight + Math.pow(this.forceY * 1.5, 2);
    Game.drawImage(this.img, screenX, screenY, size / 2, size / 2, {
      width: size,
      height: size,
      x: size,
      y: size,
    });
  }
}
