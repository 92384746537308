import { Game, Vector2 } from "./game";

export class FlyingPoint {

    position: Vector2;
    bullsEye: boolean;
    opacity: number = 1.3;
    targetHeight: number
    points: string

    y: number = 0;

    constructor(pos: Vector2, be: boolean, tH: number, points: number){
        this.position = pos;
        this.bullsEye = be;
        this.targetHeight = tH;
        this.points = points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    render(){
        const screenX = this.position.x * Game.aspectRatioWidth;
        const screenY = this.position.y * Game.aspectRatioHeight - (this.targetHeight * Game.scale) / 2;


        if(this.bullsEye){
            const font = Game.config.bullsEyeMessageSize * Game.scale + "px " + Game.config.messageFont;

            let color = this.hexToRgb(Game.config.bullsEyeMessageColor, (this.opacity > 1 ? 1 : this.opacity))

            Game.drawText((Game.config.bullsEyeMessage ? (Game.config.bullsEyeMessage + " ") : "").replace("{bonus}", this.points), screenX, screenY - this.y, font, color, "center");
        } else {
            const font = Game.config.hitMessageSize * Game.scale + "px " + Game.config.messageFont;

            let color = this.hexToRgb(Game.config.hitMessageColor, (this.opacity > 1 ? 1 : this.opacity))

            Game.drawText((Game.config.hitMessage ? (Game.config.hitMessage + " ") : "").replace("{bonus}", this.points), screenX, screenY - this.y, font, color, "center");
        }
        this.y += 2 * Game.aspectRatioHeight;
        this.opacity -= 0.03;
    }

    hexToRgb(hex: string, opacity: number) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        const color: any = {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        }
        return result ? `rgba(${color.r},${color.g},${color.b},${opacity})` : `rgba(0, 0, 0, ${opacity})`
      }
}