import { Config, _defaultConfig } from "./config";
import { Cracked } from "./cracked";
import { Dart } from "./dart";
import { FlyingPoint } from "./flying-point";
import { Platform } from "./Platform";
import { Target } from "./target";

interface Image {
	name: string;
	img: HTMLImageElement | undefined;
}

interface Crop {
	x: number;
	y: number;
	height: number;
	width: number;
}

export interface Vector2 {
	x: number;
	y: number;
}

export class Game {
	static ctx: CanvasRenderingContext2D;
	static width: number;
	static height: number;

	static points: number = 0;
	static config: Config;

	sprites = {} as any;

	images: Image[] = [];
	sounds = {
		throwSound: undefined,
		missSound: undefined,
		hitSound: undefined,
		timeBonusSound: undefined,
	};

	static scale: number;
	static aspectRatioHeight: number;
	static aspectRatioWidth: number;

	gameLoop: any;

	timeBar: HTMLDivElement;

	targets: Target[] = [];
	targetsHit: number = 0;
	darts: Dart[] = [];
	flyingPoints: FlyingPoint[] = [];
	crackedTargets: Cracked[] = [];

	level: number = 0;

	levelTransitionFrames: number = Math.round(Game.frameRate / 2);
	dartFrames: HTMLImageElement[] = [];

	platform: Platform | undefined = undefined;

	imageLoadedCounter: number = 0;

	gameOver: boolean = false;

	timeLimit: number = 0;
	time: number = 0;

	speedUp: number = 1;

	inLevel: boolean = false;
	timeBonus: boolean = false;
	timeLeft: number = 0;

	static frameRate: number = 45;

	constructor(config: Config) {
		Game.config = config;
		document.addEventListener("DOMContentLoaded", () => {
			this.preload();
		});
		window.addEventListener("resize", () => {
			this.changeScreenSize();
		});
	}

	startGameLoop() {
		this.inLevel = true;
		clearInterval(this.gameLoop);
		this.gameLoop = setInterval(() => {
			this.render();
		}, 1000 / Game.frameRate);
	}

	preload() {
		let tmp: any = {};
		if (Game.config.target)
			tmp["target"] = Game.config.path + "/" + Game.config.target;

		if (Game.config.targetHit)
			tmp["target_back"] = Game.config.path + "/" + Game.config.targetHit;

		if (Game.config.targetCrack)
			tmp["target_crack"] =
				Game.config.path + "/" + Game.config.targetCrack;

		for (let i = 0; i < Game.config.dartAnimFrames; i++) {
			tmp["dartAnim_" + (i + 1)] =
				Game.config.path +
				Game.config.dartAnimPath +
				"/" +
				(i + 1) +
				".png";
		}

		if (Game.config.dartMiss)
			tmp["dart_miss"] = Game.config.path + Game.config.dartMiss;

		if (Game.config.dartHit)
			tmp["dart_hit"] = Game.config.path + Game.config.dartHit;

		let keepGoing = true;
		let index = 0;

		while (keepGoing) {
			index += 1;
			const front = Game.config["target" + index];
			if (front) {
				tmp["target_front_" + index] = Game.config.path + front;
			}
			const back = Game.config["targetHit" + index];
			if (back) {
				tmp["target_back_" + index] = Game.config.path + back;
			}
			const crack = Game.config["targetCrack" + index];
			if (crack) {
				tmp["target_crack_" + index] = Game.config.path + crack;
			}
			if (!crack && !front && !back) {
				keepGoing = false;
			}
			console.log("TARGET" + index, tmp);
		}

		for (const [key, value] of Object.entries(tmp)) {
			let img = new Image();
			img.onload = () => {
				this.images.push({
					img: img,
					name: key,
				});
				this.imageReady();
			};
			img.onerror = () => {
				console.error(
					"Img " + key + " with source " + value + " not found"
				);
				this.imageReady();
			};
			this.imageLoadedCounter += 1;
			if (value) img.src = value.toString();
		}
	}

	loadAnimation(key: string, path: string, tmp: any, targetI: number) {
		if (path[0] !== "/") {
			return false;
		}

		const numberOfFrames = Game.config["target" + targetI + "Frames"];

		if (!numberOfFrames) {
			return false;
		}

		for (let i = 0; i < numberOfFrames; i++) {
			const index = i + 1;

			const file = Game.config.path + "/" + path + "/" + index + ".png";
			tmp[key + "_" + index] = file;
		}
	}

	imageReady() {
		this.imageLoadedCounter--;
		if (this.imageLoadedCounter === 0) {
			this.load();
		}
	}

	nextLevel() {
		this.inLevel = false;
		this.timeBonus = true;
		this.timeLeft = this.time;

		let fadeOut: any;

		// Last level
		if (
			this.level + 1 === Game.config.levels.length &&
			!Game.config.loopLevels
		) {
			return;
		}

		for (let i = 1; i < 50; i++) {
			setTimeout(() => {
				this.playSound("timeBonusSound");
			}, 10 * (i - 1));
		}

		setTimeout(() => {
			fadeOut = setInterval(() => {
				Game.ctx.globalAlpha -= 1 / this.levelTransitionFrames;
			}, 1000 / Game.frameRate);
		}, 500);

		let fadeIn: any;

		setTimeout(() => {
			this.timeBonus = false;

			clearInterval(fadeOut);
			this.targets = [];
			this.darts = [];
			this.flyingPoints = [];
			this.crackedTargets = [];
			this.targetsHit = 0;
			this.level++;
			if (this.level >= Game.config.levels.length) {
				this.level = 0;
				this.speedUp *= 1 - Game.config.speedUp;
			}
			this.loadLevel();
			fadeIn = setInterval(() => {
				Game.ctx.globalAlpha += 1 / this.levelTransitionFrames;
			}, 1000 / Game.frameRate);

			this.time = this.timeLimit;
			this.inLevel = true;
		}, 500 + this.levelTransitionFrames * (1000 / Game.frameRate));

		setTimeout(() => {
			clearInterval(fadeIn);
			Game.ctx.globalAlpha = 1;
		}, (2 * this.levelTransitionFrames * 1000) / Game.frameRate + 300);
	}

	dartScale: number = 1;

	loadLevel() {
		const level = Game.config.levels[this.level];
		this.dartScale = level.dartScale;
		this.timeLimit = level.timeLimit * this.speedUp;
		if (this.timeLimit < Game.config.minLevelTime) {
			this.timeLimit = Game.config.minLevelTime;
		}
		this.time = this.timeLimit;
		const targets = level.targets;
		for (let i = 0; i < targets.length; i++) {
			const target = targets[i];

			console.log(target);

			const pos = target.position;
			const motion = target.motion;

			const sprite = target.sprite;

			const points = target.points
				? target.points
				: Game.config.hitPoints;
			const bullsEyePoints = target.bullsEyePoints
				? target.bullsEyePoints
				: Game.config.bullsEyePoints;
			const scale = target.scale || 1;

			const bullsEyeRadius =
				target.bullsEyeRadius ?? Game.config.bullsEyeRadius;

			const hitAreaRadius = target.hitRadius ?? 100;

			this.targets.push(
				new Target(
					this,
					pos ? pos.x : 0,
					pos ? pos.y : 0,
					sprite,
					points,
					bullsEyePoints,
					hitAreaRadius,
					bullsEyeRadius,
					scale,
					motion
				)
			);
		}
	}

	load() {
		const canvas = <HTMLCanvasElement>document.getElementById("canvas");

		this.timeBar = <HTMLDivElement>document.getElementById("myBar");
		(<HTMLElement>document.getElementById("myProgress")).style.borderColor =
			Game.config.timeBarBorderColor;
		(<HTMLElement>document.getElementById("myProgress")).style.borderWidth =
			Game.config.timeBarBorderWidth + "px";
		(<HTMLElement>(
			document.getElementById("myProgress")
		)).style.borderRadius = Game.config.timeBarBorderRadius + "px";
		(<HTMLElement>document.getElementById("myProgress")).style.top =
			40 + Game.config.timeBarOffsetY + "px";

		this.timeBar.style.backgroundColor = Game.config.timeBarColor;
		(<HTMLElement>(
			document.getElementById("myBarBackground")
		)).style.backgroundColor = Game.config.timeBarBackgroundColor;

		if (canvas === null) {
			console.error("No canvas was found");
			return;
		}

		this.sortSprites();

		canvas.addEventListener("click", (e) => {
			this.canvasClicked(e as MouseEvent);
		});

		canvas.addEventListener("touchend", (e) => {
			this.canvasClicked(e as TouchEvent);
		});

		const context = canvas.getContext("2d");

		if (context === null) {
			return;
		}
		Game.ctx = context;

		this.changeScreenSize();

		this.platform = new Platform();

		this.platform.preloadSounds(Game.config);

		this.platform.init(this, Game.config);

		clearInterval(this.gameLoop);
		Game.points = 0;
		this.gameOver = false;
		this.targets = [];
		this.darts = [];
		this.flyingPoints = [];
		this.crackedTargets = [];
		this.targetsHit = 0;
		this.level = 0;
		this.speedUp = 1;
		this.loadLevel();
		this.render();
	}

	public play() {
		if (this.platform === undefined) {
			return;
		}

		this.platform.gamestarted();
		this.startGameLoop();
	}

	restart() {
		clearInterval(this.gameLoop);
		Game.points = 0;
		this.gameOver = false;
		this.targets = [];
		this.darts = [];
		this.flyingPoints = [];
		this.crackedTargets = [];
		this.targetsHit = 0;
		this.level = 0;
		this.speedUp = 1;
		this.loadLevel();
		this.render();
		this.platform.ready();
	}

	sortSprites() {
		const targetSprites = this.images.filter((img) =>
			img.name.toLowerCase().includes("target")
		);

		targetSprites.forEach((t) => {
			const img = t.img;
			img.width = 50;
			img.height = 50;
			this.sprites[t.name] = img;
		});

		this.sprites.animation = this.images
			.filter((img) => img.name.split("_")[0] === "dartAnim")
			.sort((a, b) => +a.name.split("_")[1] - +b.name.split("_")[1])
			.map((img) => img.img);

		this.sprites.dartHit = this.images.find(
			(img) => img.name === "dart_hit"
		).img;

		this.sprites.dartMiss = this.images.find(
			(img) => img.name === "dart_miss"
		).img;

		const targetCrack = this.images.find(
			(img) => img.name === "target_crack"
		);
		this.sprites.targetCrack = targetCrack ? targetCrack.img : undefined;

		// this.sprites.target_crack.width = 50;
		// this.sprites.target_crack.height = 50;
	}

	canShoot: boolean = true;

	canvasClicked(e: MouseEvent | TouchEvent) {
		if (this.gameOver || this.inLevel === false) {
			return;
		}
		let x: number, y: number;
		if (e instanceof MouseEvent) {
			x = e.clientX;
			y = e.clientY;
		} else {
			if (e.touches.length === 0) {
				return;
			}
			x = e.touches[0].clientX;
			y = e.touches[0].clientY;
		}

		this.throwDart(x, y);
	}

	throwDart(x: number, y: number) {
		if (this.gameOver) {
			return;
		}
		let dart: Dart = new Dart(
			x,
			y,
			this.sprites.animation,
			this.sprites.dartHit,
			this.sprites.dartMiss,
			this.dartScale
		);
		this.playSound("throwSound");
		this.darts.push(dart);
		setTimeout(() => {
			if (this.gameOver) {
				this.playSound("missSound");

				dart.miss();
				return;
			}
			for (let target of this.targets) {
				if (target.tryToHit({ x: x, y: y }) === true) {
					if (!this.playSound("hitSound" + target.sprite)) {
						this.playSound("hitSound");
					}

					dart.hit(target);
					const points = target.bullsEye
						? target.bullsEyePoints
						: target.points;

					this.flyingPoints.push(
						new FlyingPoint(
							{ ...target.position },
							target.bullsEye,
							target.spriteFront.height * target.scale,
							points
						)
					);

					this.crackedTargets.push(
						new Cracked(
							this,
							{ ...target.position },
							target.scale,
							target.sprite
						)
					);

					this.targetsHit++;
					if (this.targetsHit === this.targets.length) {
						this.canShoot = false;
						this.nextLevel();
					}
					return;
				}
			}
			this.playSound("missSound");

			dart.miss();
			if (this.targetsHit === this.targets.length) {
				return;
			}

			this.time -= this.time * (Game.config.missPenalty / 100);
		}, (Game.config.dartAnimFrames * 1000) / Game.frameRate);
	}
	audioPlaying: string[] = [];

	playSound(soundKey: string | undefined, loop: boolean = false) {
		const config = Game.config as any;
		if (config[soundKey]) {
			this.platform.playSound(config.path + config[soundKey]);
			return true;
		}
		return false;
	}

	triggerGameOver(timeUp: boolean = false) {
		this.gameOver = true;
		this.inLevel = false;
		if (timeUp) {
			this.platform.gameover(Math.round(Game.points));
		} else {
			setTimeout(() => {
				this.platform.gameover(Math.round(Game.points));
			}, 300);
		}
	}

	render() {
		Game.ctx.clearRect(0, 0, Game.width, Game.height);

		const font =
			Game.config.scoreSize * Game.scale + "px " + Game.config.scoreFont;

		if (this.inLevel) {
			this.time -= 1000 / Game.frameRate;
			if (this.time <= 0 && this.gameOver === false) {
				this.triggerGameOver(true);
			}
		}

		if (this.timeBonus) {
			this.time -= this.timeLeft / (1000 / Game.frameRate);
			if (this.time > 0) {
				Game.points +=
					(this.timeLeft / (1000 / Game.frameRate)) *
					Game.config.bonusPerMs *
					(1 + this.speedUp);
			} else {
				this.timeBonus = false;
				if (
					this.level + 1 === Game.config.levels.length &&
					!Game.config.loopLevels
				) {
					this.triggerGameOver();
					return;
				}
			}
		}

		Game.drawText(
			Math.round(Game.points)
				.toString()
				.replace(/\B(?=(\d{3})+(?!\d))/g, "."),
			Game.width / 2,
			(20 + Game.config.scoreOffsetY + Game.config.scoreSize / 2) *
				Game.scale,
			font,
			Game.config.scoreColor
		);

		let width = (this.time / this.timeLimit) * 100;
		if (width < 0) {
			width = 0;
		}

		this.timeBar.style.width = width + "%";

		this.targets.forEach((target) => {
			target.update();
			target.render();
		});

		this.darts.sort((a, b) => a.position.y - b.position.y);
		this.darts.forEach((dart) => {
			dart.render();
			if (dart.position.y > 800) {
				this.darts.splice(this.darts.indexOf(dart), 1);
			}
		});

		this.crackedTargets.forEach((crack) => {
			//   if (crack.position.y > 800) {
			// this.crackedTargets.splice(this.crackedTargets.indexOf(crack), 1);
			//   }
			crack.render();
		});
		this.flyingPoints.forEach((point) => {
			point.render();
			if (point.opacity <= 0) {
				this.flyingPoints.splice(this.flyingPoints.indexOf(point), 1);
			}
		});
	}

	changeScreenSize() {
		Game.height = window.innerHeight;
		Game.width = window.innerWidth;

		Game.ctx.canvas.height = Game.height;
		Game.ctx.canvas.width = Game.width;

		Game.aspectRatioHeight = Game.height / 500;
		Game.aspectRatioWidth = Game.width / 375;
		Game.scale = Math.min(Game.aspectRatioWidth, Game.aspectRatioHeight);
	}

	static drawImage(
		image: HTMLImageElement,
		x: number,
		y: number,
		width: number,
		height: number,
		crop?: Crop
	) {
		if (this.ctx === null) {
			return;
		}
		console.log(image.width, image.height);
		if (crop) {
			this.ctx.drawImage(
				image,
				crop.x,
				crop.y,
				crop.width,
				crop.height,
				x,
				y,
				width,
				height
			);
		} else {
			this.ctx.drawImage(image, x, y, width, height);
		}
	}

	static drawShape(
		color: string,
		x: number,
		y: number,
		width: number,
		height: number,
		square: boolean = true
	) {
		if (this.ctx === null) {
			return;
		}
		if (height < 0) {
			height = 0;
		}
		this.ctx.fillStyle = color;
		if (square) {
			this.ctx.fillRect(x, y, width, height);
		} else {
			this.ctx.beginPath();
			this.ctx.arc(x, y, width, 0, 2 * Math.PI);
			this.ctx.fill();
		}
	}

	static drawText(
		text: string,
		x: number,
		y: number,
		font: string,
		color: string = "white",
		align: CanvasTextAlign = "center"
	) {
		if (this.ctx === null) {
			return;
		}

		this.ctx.textAlign = align;
		this.ctx.font = font;
		this.ctx.fillStyle = color;
		const fontSize = parseInt(font);

		const textSplitAtNewLine = text.split("\n");
		if (textSplitAtNewLine.length < 2) {
			this.ctx.fillText(text, x, y);
			return;
		}

		for (let i = 0; i < textSplitAtNewLine.length; i++) {
			const line = textSplitAtNewLine[i];
			this.ctx.fillText(line, x, y + fontSize * i);
		}
	}
}
