// Message to the platform
export type EventTypes =
  | "start"
  | "gameover"
  | "ready"
  | "sound"
  | "preload-sounds";

export interface GameOverData {
  score: number;
}
export type EventData = GameOverData | string | string[];
export interface GameEvent {
  eventName: EventTypes;
  data?: EventData;
}

// Messages from the platform
export type PlatformEventTypes = "play" | "restart";
export interface PlatFormEvent {
  eventName: PlatformEventTypes;
}

export interface Game {
  play: any;
  restart: any;
}

export class Platform {
  private game: Game | undefined = undefined;
  constructor() {
    window.addEventListener("message", (ev: MessageEvent) => {
      this.receive(ev.data);
    });

    (<any>window).pew = this;
  }

  private receive(data: PlatFormEvent) {
    if (!this.game) {
      console.error("Error: Game not set");
      return;
    }
    switch (data.eventName) {
      case "play":
        this.game.play(); // Send play message to the game
        break;
      case "restart":
        this.game.restart();
    }
  }

  public init(game: Game, config: any) {
    this.game = game;

    // Load fonts and then send ready event
    let fonts: string[] = [];
    for (const [key, v] of Object.entries(config)) {
      if (!v) {
        continue;
      }
      const value = "" + v;
      if (
        key.includes("Font") &&
        !fonts.includes(value) &&
        !excludedFonts.includes(value)
      ) {
        fonts.push(value);
      }
    }

    if (fonts.length > 0) {
      import("webfontloader").then((webfont) => {
        webfont.load({
          google: { families: fonts },
          active: () => {
            this.ready();
          },
        });
      });
    } else {
      this.ready();
    }
  }

  /**
   * Retrieve play event when game actualy starts
   */
  public gamestarted() {
    // console.log('G: SEND', 'play');
    this.sendEvent({ eventName: "start" });
  }

  public play() {
    this.game?.play();
  }

  /**
   * Retrieve gameover event from game whith endsoce
   */
  public gameover(score: number) {
    this.sendEvent({ eventName: "gameover", data: { score } });
  }

  /**
   * Receive ready event from game when allowed to use start
   */
  public ready() {
    this.sendEvent({ eventName: "ready" });
  }

  public playSound(sound: string) {
    console.log(sound);
    this.sendEvent({ eventName: "sound", data: sound });
  }

  public preloadSounds(config: any) {
    let files: string[] = [];

    for (const [key, v] of Object.entries(config)) {
      if (!v) {
        continue;
      }
      const value = config.path + "/" + v;
      if (!files.includes(value) && key.includes("Sound")) {
        files.push(value);
      }
    }
    this.sendEvent({
      eventName: "preload-sounds",
      data: files,
    });
  }

  /**
   * Send message to the game platform
   * @param event
   */
  private sendEvent(event: GameEvent) {
    window?.top?.postMessage(event, "*");
  }
}

const excludedFonts = [
  "Arial",
  "Helvetica",
  "Comic Sans MS",
  "Courier New",
  "Courier",
  "Georgia",
  "Lucida Sans Unicode",
  "Lucida Grande",
  "Geneva",
  "Times New Roman",
  "Trebuchet",
  "Verdana",
];
